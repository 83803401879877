import React, { useEffect, useState } from "react";
import { cloneDeep, findIndex, isArray, isEmpty } from "lodash";
import { DateTime } from "luxon";
import { filter as rxFilter, pluck } from "rxjs/operators";
import { onPusher } from "fetcher!sofe";
import { useHasAccess } from "cp-client-auth!sofe";
import { CpTable } from "canopy-styleguide!sofe";
import { featureEnabled } from "feature-toggles!sofe";
import { transcriptStatusTypes } from "src/common/transcripts-status.helper";
import { handleError } from "src/error";
import { useTranscriptsQuery } from "./use-transcritps-query.hook";
import { getTranscriptsListSchema } from "./table/transcripts-table-schema";

export const TranscriptsContext = React.createContext(null);

export const useTranscriptsData = (
  activeTab,
  activeOrgs,
  transcriptsCount,
  isCrmHeirarchy,
  setShowRequestDialog,
  setShowErrorDialog,
  setSettingsModalIds
) => {
  const [allItems, setAllItems] = useState([]);
  const [page, setPage] = useState(0);
  const [sortData, setSortData] = useState({
    sortColumn: null,
    sortDirection: null,
  });
  const [filters, setFilters] = useState({
    client_id: [],
    client_name: [],
    last_pull_date: {},
    last_successful_pull_date: {},
    status: [],
  });
  const [selectedItems, setSelectedItems] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [pendingNewClients, setPendingNewClients] = useState([]);
  const [isNewClient, setIsNewClient] = useState(false);

  const canPullTranscripts = useHasAccess("transcripts_pull");

  const { transcripts, transcriptsQuery, pageCount } = useTranscriptsQuery({
    page,
    limit: 50,
    filters: filters,
    sortData: sortData,
    disabled: activeTab !== "ALL",
    isNewClient,
  });

  useEffect(() => {
    const storedFilterParams = localStorage.getItem("transcripts-filters");

    if (storedFilterParams) {
      const { filters: storedFilters, sortData: storedSortData } =
        JSON.parse(storedFilterParams);

      if (storedSortData) setSortData(storedSortData);
      if (storedFilters) setFilters(storedFilters);

      mapStoredFiltersToFilterControl(storedSortData, storedFilters);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const transcriptsPusherSub = onPusher("transcripts")
      .pipe(
        rxFilter((msg) => msg.data_needed === "status_change"),
        pluck("details")
      )
      .subscribe((details) => {
        const transcriptsList = cloneDeep(allItems);

        let transcriptIndex = findIndex(transcriptsList, (transcript) => {
          return details.transcript_id === transcript.transcript_id;
        });

        if (transcriptIndex < 0) {
          transcriptIndex = findIndex(transcriptsList, (transcript) => {
            if (details.contact_id) {
              return (
                transcript.client_id === details.client_id &&
                transcript.contact_id === details.contact_id
              );
            } else {
              return transcript.client_id === details.client_id;
            }
          });
        }

        if (transcriptIndex >= 0) {
          // update transcript row without fetching all transcripts again
          transcriptsList[transcriptIndex].transcript_id =
            details.transcript_id;
          transcriptsList[transcriptIndex].category = details.category;
          transcriptsList[transcriptIndex].status = details.label;
          transcriptsList[transcriptIndex].last_pull_date =
            DateTime.utc().toISO({
              includeOffset: false,
            });
          transcriptsList[transcriptIndex].last_successful_pull_date =
            details.category === transcriptStatusTypes.SUCCESS
              ? DateTime.utc().toISO({ includeOffset: false })
              : transcriptsList[transcriptIndex].last_successful_pull_date ||
                "";
          transcriptsList[transcriptIndex].has_settings =
            details.status === "missing_settings" ? false : true;

          setAllItems(transcriptsList);
        } else if (pendingNewClients.includes(details.client_id)) {
          setPendingNewClients(
            pendingNewClients.filter((id) => id !== details.client_id)
          );
          setIsNewClient(true);
        }
      }, handleError);

    return () => {
      transcriptsPusherSub.unsubscribe();
    };
  }, [allItems, pendingNewClients, transcriptsQuery]);

  useEffect(() => {
    if (!transcripts) return;
    setSelectedItems([]);
    setAllSelected(false);
    setAllItems(transcripts);
  }, [transcripts]);

  const updateFilterParams = (
    column,
    sortDirection,
    filterSelections,
    persistSort
  ) => {
    const { columnType, sortName } = column;

    let _filterSelections = filterSelections;
    if (
      Array.isArray(filterSelections) &&
      (filterSelections[0].start_date || filterSelections[0].end_date)
    ) {
      _filterSelections = {
        after_date: DateTime.fromISO(filterSelections[0].start_date)
          .toUTC()
          .toISO(),
        before_date: DateTime.fromISO(filterSelections[0].end_date)
          .toUTC()
          .toISO(),
      };
    }

    const sortUpdated =
      sortName !== sortData?.sortColumn ||
      sortDirection !== sortData.sortDirection;

    const filterUpdated = filters[columnType] !== _filterSelections;

    let newTableSort = sortData;
    if ((!sortUpdated || persistSort) && !filterUpdated) {
      return;
    } else if (persistSort) {
      newTableSort = sortData;
    } else if (sortUpdated && !sortDirection) {
      newTableSort = { sortColumn: null, sortDirection: null };
    } else if (sortUpdated) {
      newTableSort = { sortColumn: sortName, sortDirection };
    }

    let newFilterParams = { ...filters };

    if (filterUpdated) {
      newFilterParams = {
        ...filters,
        [columnType]: _filterSelections,
      };
    }

    localStorage.setItem(
      "transcripts-filters",
      JSON.stringify({
        sortData: newTableSort,
        filters: newFilterParams,
      })
    );

    setSortData(newTableSort);
    setFilters(newFilterParams);
    setPage(0);
  };

  const setItemsToPending = (itemsToUpdate = []) => {
    const transcriptsList = cloneDeep(allItems);

    itemsToUpdate.forEach((clientId) => {
      const transcriptIndex = findIndex(
        transcriptsList,
        (transcript) => transcript.client_id === clientId
      );
      if (transcriptIndex >= 0) {
        // update transcript row without fetching all transcripts again
        transcriptsList[transcriptIndex].category =
          transcriptStatusTypes.PENDING;
        transcriptsList[transcriptIndex].status = "Pending";
        transcriptsList[transcriptIndex].last_pull_date = DateTime.utc().toISO({
          includeOffset: false,
        });
      }
    });

    setAllItems(transcriptsList);
  };

  const removeTranscriptsRow = (clientId) => {
    // remove transcript row without fetching all transcripts again
    const itemList = allItems.filter(
      (transcript) => transcript.client_id !== clientId
    );
    actions.setAllItems(itemList);
  };

  const updateRowSettings = (settings) => {
    const transcriptsList = cloneDeep(allItems);

    const transcriptIndex = findIndex(
      transcriptsList,
      (transcript) => transcript.client_id === settings.client_id
    );

    if (transcriptIndex >= 0) {
      // update transcript settings for row without fetching all transcripts again
      transcriptsList[transcriptIndex].cadence = settings.cadence;
      transcriptsList[transcriptIndex].next_run_at = settings.next_run_at;
      transcriptsList[transcriptIndex].poa_expiration_at =
        settings.poa_expiration_at;
      transcriptsList[transcriptIndex].irs_token_id = settings.irs_token_id;
      transcriptsList[transcriptIndex].has_settings = true;
    }

    actions.setAllItems(transcriptsList);
  };

  const selectItem = (item) => {
    setSelectedItems([
      ...selectedItems,
      item.transcript_id || item.irs_token_id,
    ]);
  };

  const deselectItem = (item) => {
    const newSelectedItems = selectedItems.filter(
      (id) => id !== item.transcript_id
    );
    setAllSelected(false);
    setSelectedItems(newSelectedItems);
  };

  const selectAllItems = () => {
    const newSelectedItems = allItems.map((item) => item.transcript_id);
    setAllSelected(true);
    setSelectedItems(newSelectedItems);
  };

  const deselectAllItems = () => {
    setAllSelected(false);
    setSelectedItems([]);
    selection.deselectAll();
  };

  const schema = CpTable.useSchema(
    () =>
      getTranscriptsListSchema({
        activeOrgs,
        canPullTranscripts,
        setShowRequestDialog,
        setShowErrorDialog,
        setSettingsModalIds,
        transcriptsCount,
        isCrmHeirarchy,
      }),
    [
      activeOrgs,
      canPullTranscripts,
      setShowRequestDialog,
      setSettingsModalIds,
      transcriptsCount,
      isCrmHeirarchy,
    ]
  );

  const onFilterApply = ({
    filterFieldId,
    filterData,
    sortFieldId,
    sortDir,
  }) => {
    let newFilterData = {};
    if (filterData?.[0].filterValue) {
      newFilterData = filterData?.[0].filterValue.map((item) => item.id);
    } else if (isArray(filterData)) {
      newFilterData = filterData;
    }

    updateFilterParams(
      {
        columnType: filterFieldId,
        sortName: sortFieldId,
      },
      sortDir === "asc" ? "+" : "-",
      newFilterData,
      false
    );
  };

  const data = allItems?.map((item) => ({ ...item, id: item.transcript_id }));
  const selection = CpTable.useBasicSelection({
    resources: data,
  });
  const filterControl = CpTable.useFilters({
    schema,
    onFilterApply,
    singleSort: true,
  });

  const mapStoredFiltersToFilterControl = (storedSortData, storedFilters) => {
    const fieldToSchemaMap = {
      client_id: "clientId",
      client_name: "clientId",
      contact_id: "contactId",
      next_run_at: "nextRun",
      last_pull_date: "lastAttempt",
      last_successful_pull_date: "lastSuccess",
      label: "status",
    };
    if (storedSortData) {
      const newSort = {
        [fieldToSchemaMap[storedSortData.sortColumn]]: {
          fieldId: storedSortData.sortColumn,
          direction: storedSortData.sortDirection === "+" ? "asc" : "desc",
        },
      };
      filterControl.setSortData(newSort);
    }
    if (storedFilters) {
      const newFilters = {};
      for (const [fieldId, data] of Object.entries(storedFilters)) {
        if (!isEmpty(data)) {
          newFilters[fieldToSchemaMap[fieldId]] = {
            fieldId,
            data,
          };
        }
      }
      filterControl.setFilters(newFilters);
    }
  };

  const actions = {
    setAllItems,
    setPage,
    refetch: transcriptsQuery.refetch,
    updateFilterParams,
    removeTranscriptsRow,
    updateRowSettings,
    selectItem,
    deselectItem,
    selectAllItems,
    deselectAllItems,
    setItemsToPending,
    setPendingNewClients,
  };

  const permissions = {
    canPullTranscripts,
  };

  return {
    loading: transcriptsQuery.isLoading,
    allItems,
    page,
    lastPage: pageCount,
    sortData,
    filters,
    selectedItems: featureEnabled("toggle_transcripts_table")
      ? selection.toArray()
      : selectedItems,
    allSelected,
    pendingNewClients,
    schema,
    data,
    selection,
    filterControl,
    actions,
    permissions,
  };
};
